import { JwtPayload } from "jwt-decode";

export const PORTFOLIO_MONITOR_URL = "portfoliomonitor.lunaristrading.co";

export enum Theme {
  DEFAULT = "theme-default",
  DARK = "theme-dark",
}

export type Settings = {
  theme: Theme;
  positionDisplayDecimals: number;
};

export enum PortfolioMonitorColumnType {
  PORTFOLIO = "Portfolio",
  INSTRUMENT = "Instrument",
  POSITION = "Position",
}

export type PositionResponse = {
  portfolio: string;
  instrument: string;
  position: number;
};

export interface Auth0JwtPayload extends JwtPayload {
  permissions: string[];
}

// Utilities copied from tob-gui for display

function getAbbrevInstrumentType(type: string) {
  switch (type) {
    case "SPOT":
      return "";
    case "FUTURE":
      return "F";
    case "FUTURE_PERP":
      return "F Perp";
    case "INVERSE_FUTURE":
      return "F-Inv";
    case "INVERSE_FUTURE_PERP":
      return "F-Inv Perp";
    case "MOVE_DAY":
      return "MOVE";
    case "MOVE_WEEK":
      return "MOVE-WK";
    case "MOVE_QUARTER":
      return "MOVE-Q";
  }
}

function getAbbrevInstrument(inst: string) {
  const split = inst.split(";");
  const underlying = split[0];
  const type = getAbbrevInstrumentType(split[1]);
  if (split.length > 2) {
    const exchange = split[3];
    if (split[2] === "NONE") {
      return [underlying, type, exchange].join(" ");
    }
    const expDate = new Date(split[2]);
    const expiration = [
      expDate.getFullYear().toString(),
      (expDate.getMonth() + 1).toString().padStart(2, "0"),
      expDate.getDate().toString(),
    ].join("-");
    return [underlying, type, expiration, exchange].join(" ");
  }
  return [underlying, type].join(" ");
}

export function getAbbrevExchangePair(
  exchangePair: string,
  showExchange: boolean = false
) {
  const [tradingPair, exchangeName] = exchangePair.split(">");
  const [inst1, inst2] = tradingPair.split("/");
  const abbrev =
    getAbbrevInstrument(inst1) + " / " + getAbbrevInstrument(inst2);
  return abbrev + (showExchange ? ` (${exchangeName})` : "");
}
